<template>
    <div>
        <v-container fluid>
            <v-row class="d-flex justify-center">
                <v-col cols="12" lg="2" md="2">
                    <v-menu :close-on-content-click="false"
                            :return-value.sync="filterCriteria.dateRange"
                            min-width="290px"
                            offset-y
                            ref="menu3"
                            transition="scale-transition"
                            v-model="menu3">
                        <template v-slot:activator="{ on }">
                            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">
                                <v-text-field
                                        @click="resetDates"
                                        append-icon="event" dense
                                        :label="$t('msg.date_label')" outlined readonly
                                        v-model="dateRangeText"
                                        v-on="on"
                                ></v-text-field>
                            </v-skeleton-loader>
                        </template>
                        <v-date-picker :max="newDate" color="primary"
                                       no-title range scrollable v-model="filterCriteria.dateRange">
                            <v-spacer></v-spacer>
                            <v-btn @click="menu3 = false" color="primary" text>{{$t('cancel')}}</v-btn>
                            <v-btn @click="$refs.menu3.save(filterCriteria.dateRange)" color="primary" text>{{$t('ok')}}</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col :key="idx" cols="12" lg="2" md="2" v-for="(value, key, idx) in filters">
                    <v-skeleton-loader :loading="loading" transition="scale-transition"
                                       type="card-heading">

                      <v-select :items="value" :label="$t(`msg.${key}_label`)" dense outlined chips deletable-chips small-chips
                                item-text="text" item-value="value"
                                class="contain-long-inputs"
                                v-model="filterCriteria[`${key}`]"></v-select>

                    </v-skeleton-loader>
                </v-col>
                <v-col cols="12" lg="3" md="3" class="d-flex justify-center">
                    <v-skeleton-loader class="mr-3" :loading="loading" transition="scale-transition" type="button">
                    <v-btn @click="filterData" color="primary">{{$t('filter')}}</v-btn>
                    </v-skeleton-loader>
                    <v-skeleton-loader :loading="loading" transition="scale-transition" type="button">
                    <v-btn @click="resetFilters" color="primary">{{$t('reset')}}</v-btn>
                    </v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row v-if="chartLoader">
                <v-col cols="12" lg="6" md="6" v-bind:key="index" v-for="(types,index) in loaderTypes">
                    <v-skeleton-loader :loading="chartLoader" :type="types.type" transition="scale-transition"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" lg="6" md="6" v-bind:key="index" v-for="(charts,index) in firstChart">
                    <v-card elevation="5">
                        <PointChart :options="charts" v-if="charts !== null"></PointChart>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                    <v-card elevation="5">
                        <BarChart :options="barChartData" v-if="barChartData !== null"></BarChart>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import PointChart from "@/charts/PointChart";
    import BarChart from "@/charts/BarChart";
    import {dataServices} from "@/services";

    export default {
        name: "Dashboard",
        components: {
            PointChart,
            BarChart
        },
        data() {
            return {
                filterCriteria: {
                    dateRange: [],
                    scheme:'',
                    branch:'',
                },
                newDate: null,
                menu2: false,
                menu: false,
                menu3: false,
                firstChart: null,
                barChartData: null,
                computedStartDate: '',
                computedEndDate: '',
                chartLoader: true,
                currentDate: new Date().toJSON(),
                fromDate: null,
                toDate: null,
                programmeDropdown: [],
                branchDropdown: [],
                loading: false,
                filters: {},
                loaderTypes: [
                    {type: 'card'},
                    {type: 'card'},
                    {type: 'card'},
                    {type: 'card'}
                ]
            }
        },
        methods: {
            prepareDataForRequest: function () {
                if (this.filterCriteria.dateRange[0] !== null) {
                    this.filterCriteria.startDate = this.filterCriteria.dateRange[0]
                }
                if (this.filterCriteria.dateRange[1] !== null) {
                    this.filterCriteria.endDate = this.filterCriteria.dateRange[1]
                }
            },
            chartData: function () {
                this.chartLoader = true
                this.firstChart = null
                this.barChartData = null
                this.loading = true
                this.prepareDataForRequest()
                dataServices.getChartData(this.filterCriteria).then(response => {
                    if (response.dataResponse) {
                        if (response.dataResponse.firstMap) {
                            this.firstChart = response.dataResponse.firstMap
                        }
                        if (response.dataResponse.barChart) {
                            this.barChartData = response.dataResponse.barChart
                        }
                    }
                    this.loading = false
                    this.chartLoader = false
                })
            },
            homePageDropdown: function(){
                dataServices.getHomepageDropdown().then(response => {
                    this.filters = response.dataResponse
                    this.loading = false
                })
            },
            filterData: function () {
                this.chartData()
            },
            resetDates: function () {
                this.filterCriteria.dateRange = []
                this.newDate = null
            },
            resetFilters: function () {
                this.filterCriteria.customerType = null
                this.filterCriteria.dateRange = []
                this.filterCriteria.scheme = ''
                this.filterCriteria.branch = ''
                this.chartData()
                this.tempBranches = this.filters.branch
            }
        },
        computed: {
            dateRangeText() {
                return this.filterCriteria.dateRange.join(' ~ ')
            },
        },
        watch : {
          'filterCriteria.dateRange': function (newDate,oldDate) {
               if (this.filterCriteria.dateRange[1] < this.filterCriteria.dateRange[0]){
                   let temp = this.filterCriteria.dateRange[0]
                   this.filterCriteria.dateRange[0] = this.filterCriteria.dateRange[1]
                   this.filterCriteria.dateRange[1] = temp
               }
              let date = new Date(this.filterCriteria.dateRange[0])
              let endDateSelected = new Date(this.filterCriteria.dateRange[1])
              date.setFullYear(date.getFullYear(), 11, 31)
              if (date.getFullYear() < new Date().getFullYear()){
                  this.newDate = date.toJSON()
              }else {
                  this.newDate = this.currentDate
              }

                if (new Date(newDate[0]).getFullYear() < endDateSelected.getFullYear()){
                   this.filterCriteria.dateRange[1] = date.toISOString().substring(0, 10)
                }

                if (oldDate.length > 1){
                    if (newDate[0] < oldDate[0]){
                        this.filterCriteria.dateRange[0] = newDate[0]
                        this.filterCriteria.dateRange[1] = oldDate[1]
                    }

                    if (new Date(newDate[0]).getFullYear() < new Date(oldDate[0]).getFullYear()){
                        this.filterCriteria.dateRange[1] = date.toISOString().substring(0, 10)

                    }
                    if (newDate[0] > oldDate[1]){
                        this.filterCriteria.dateRange[0] = newDate[0]
                        this.filterCriteria.dateRange[1] = oldDate[0]

                        let temp = this.filterCriteria.dateRange[0]
                        this.filterCriteria.dateRange[0] = this.filterCriteria.dateRange[1]
                        this.filterCriteria.dateRange[1] = temp
                    }
                }

          }
        },
        mounted() {
            if (this.$store.state.home.user.products.length === 1) {
                this.filterCriteria.customerType = this.$store.state.home.user.products[0]
            }
            this.chartData();
            this.homePageDropdown();
        }
    }
</script>

<style>
.v-select--chips.contain-long-inputs .v-select__selections input {
  display: none;
}
</style>
